import { stringToNumber } from 'components/shared/Inputs/FileUpload/utils';
import api from 'utils/axios';

export const getSKUs = async (searchParams) => {
  try {
    const response = await api(`/merchants/skus?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getSkuById = async (id) => {
  try {
    const response = await api(`/merchants/skus/${id}`);
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getSkuOnHandInventory = async (id, searchParams) => {
  try {
    const response = await api(`/merchants/skus/${id}/on_hand?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getSkuReservedInventory = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/skus/${id}/reserved?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getSkuShippedInventory = async (id, searchParams) => {
  try {
    const response = await api(`/merchants/skus/${id}/shipped?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

const skuFormToPayload = (sku) => ({
  ...sku,
  height: stringToNumber(sku.height),
  length: stringToNumber(sku.length),
  upc: stringToNumber(sku.upc),
  value: stringToNumber(sku.value),
  weight: stringToNumber(sku.weight),
  width: stringToNumber(sku.width),
  near_expiry_threshold: stringToNumber(sku.near_expiry_threshold),
});

export const createSKU = async (sku) => {
  const payload = skuFormToPayload(sku);

  try {
    const response = await api.post('/merchants/skus', { sku: payload });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSKUCategories = async () => {
  try {
    const response = await api('/merchants/categories');
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getOnHandSKUs = async () => {
  try {
    const response = await api(
      `/merchants/skus?in_inventory=true&q[s]=created_at+desc`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const updateSKU = async (id, sku) => {
  const payload = skuFormToPayload(sku);

  try {
    const response = await api.patch(`/merchants/skus/${id}`, { sku: payload });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOrganizationThreshold = async (id) => {
  try {
    const response = await api.get(`/organizations/${id}/threshold`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAssociatedMovementsInbounds = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/skus/${id}/inbounds?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getAssociatedMovementsOutbounds = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/skus/${id}/outbounds?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const importSKU = async (csvFile) => {
  const payloadFormData = new FormData();
  payloadFormData.append('skus[file]', csvFile);

  try {
    const response = await api.post('/merchants/skus/import', payloadFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOutboundSKUConfigurations = async (
  id,
  packagingType,
  outboundId
) => {
  try {
    const response = await api(
      `/merchants/skus/${id}/configurations?packaging_type=${packagingType}&outbound_id=${outboundId}`
    );

    return { data: response.data.data };
  } catch (error) {
    throw error;
  }
};
