import { Flex, Stack, Text } from '@chakra-ui/react';

const EmptyTable = ({
  illustration,
  title,
  subtitle,
  actions,
  noPadding,
  maxWidth = '570px',
}) => {
  return (
    <Flex height="full" alignItems="center" justifyContent="center">
      <Stack
        spacing={6}
        paddingY={8}
        paddingX={{ md: 8, base: noPadding ? 2 : 8 }}
        background="white"
        borderRadius="3xl"
        width="full"
        maxWidth={maxWidth}
        textAlign="center"
        alignItems="center"
      >
        {illustration}

        <Stack spacing={3}>
          <Text fontSize="2xl" fontWeight={700} lineHeight={8}>
            {title}
          </Text>
          <Text as="div" color="gray.500" fontWeight={500}>
            {subtitle}
          </Text>
        </Stack>

        {actions}
      </Stack>
    </Flex>
  );
};

export default EmptyTable;
