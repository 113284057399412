import { useEffect, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';

import {
  Overview,
  SignUp,
  VerifyOTP,
  VerifyOTPSuccess,
  CreatePassword,
  CreatePasswordSuccess,
  ForgotPassword,
  SignIn,
  NotFound,
} from 'pages';

import Layout from 'components/Layouts/Layout/Layout';
import DashboardLayout from 'components/Layouts/DashboardLayout/DashboardLayout';
import OnboardingLayout from 'components/Layouts/OnboardingLayout';
import Inbounds from 'pages/Dashboard/Inbounds/Index';
import Lead from 'pages/Lead/Lead';
import Outbounds from 'pages/Dashboard/Outbounds/Index';
import Transportations from 'pages/Dashboard/Transportations';
import Addresses from 'pages/Dashboard/Addresses';
import Suppliers from 'pages/Dashboard/Suppliers';
import WarehousesPage from 'pages/Dashboard/Warehouses';

import { initAmplitude } from 'utils/amplitude';
import CreateAddress from 'pages/Dashboard/Addresses/CreateAddress';
import AddressDetails from 'pages/Dashboard/Addresses/AddressDetails';
import SKUs from 'pages/Dashboard/SKUs/Index';
import Profile from 'pages/Dashboard/Settings/Profile';
import Reports from 'pages/Dashboard/Reports';
import SkuDetails from 'pages/Dashboard/SKUs/SkuDetails';
import TagManager from 'react-gtm-module';
import InboundDetails from 'pages/Dashboard/Inbounds/InboundDetails';
import OutboundDetails from 'pages/Dashboard/Outbounds/OutboundDetails';
import SKUGroups from 'pages/Dashboard/SKUGroups/Index';
import InboundCreate from 'pages/Dashboard/Inbounds/InboundCreate';
import InboundAssign from 'pages/Dashboard/Inbounds/InboundAssign';
import InboundEdit from 'pages/Dashboard/Inbounds/InboundEdit';
import OutboundCreate from 'pages/Dashboard/Outbounds/OutboundCreate';
import OutboundAssign from 'pages/Dashboard/Outbounds/OutboundAssign';
import OutboundEdit from 'pages/Dashboard/Outbounds/OutboundEdit';
import SKUCreate from 'pages/Dashboard/SKUs/SKUCreate';
import SKUEdit from 'pages/Dashboard/SKUs/SKUEdit';
import SKUGroupsCreate from 'pages/Dashboard/SKUGroups/SKUGroupsCreate';
import SKUGroupsEdit from 'pages/Dashboard/SKUGroups/SKUGroupsEdit';
import UserManagement from 'pages/Dashboard/UserManagement/Index';
import Settings from 'pages/Dashboard/Settings';
import NotificationSettings from 'pages/Dashboard/Settings/NotificationSettings';
import AssociatedMovements from 'pages/Dashboard/SKUs/AssociatedMovements';
import Billing from 'pages/Dashboard/Settings/Billing';
import TransportationCreate from 'pages/Dashboard/Transportations/TransportationCreate';
import TransportationSummary from 'pages/Dashboard/Transportations/TransportationSummary';
import TransportationDetails from 'pages/Dashboard/Transportations/TransportationDetails';
import New from 'pages/MagicLinks/New';
import Validate from 'pages/MagicLinks/Validate';
import Warehouses from 'pages/Dashboard/Warehouses/Warehouses';
import Quotations from 'pages/Dashboard/Warehouses/Quotations';
import ReviewQuote from 'pages/Dashboard/Warehouses/ReviewQuote';
import { initIntercom } from 'utils/intercom';
import WarehouseSuccess from 'pages/Dashboard/Warehouses/Success.jsx';
import { usePaymentDue } from 'context/PaymentDueContext';
import { setupInterceptors } from 'utils/axios';
import Organization from 'pages/Dashboard/Settings/Organization';
import { useUnverifiedOrganizationModal } from 'context/UnverifiedOrganizationModal';
import useLanguage from 'hooks/useLanguage';
import { useToast } from '@chakra-ui/react';
import { useAppVersionPoller } from 'hooks/useAppVersionPoller';
import VersionUpdateToast from 'components/shared/VersionUpdateToast';
import { useAmplitudePageTracking } from 'hooks/useAmplitudePageTracking';

const isProduction = process.env.REACT_APP_ENV === 'production';

const App = () => {
  const toast = useToast();
  const { language } = useLanguage();
  const isAr = language === 'ar';

  const { updatePaymentDue } = usePaymentDue();
  const isSetupInterceptorsDone = useRef(false);

  const { showModal } = useUnverifiedOrganizationModal();

  const { isNewVersionAvailable } = useAppVersionPoller({
    deploymentUrl: window.location.origin,
  });

  useAmplitudePageTracking();

  const versionToastId = useRef(null);

  useEffect(() => {
    if (isProduction) {
      TagManager.initialize({
        gtmId: 'GTM-PGM5HL7X',
      });

      initIntercom();
    }

    initAmplitude();
  }, []);

  useEffect(() => {
    if (isSetupInterceptorsDone.current) {
      return;
    }

    if (!updatePaymentDue || !showModal) {
      return;
    }

    setupInterceptors(updatePaymentDue, showModal);
    isSetupInterceptorsDone.current = true;
  }, [updatePaymentDue, showModal]);

  useEffect(() => {
    const toastId = versionToastId.current;

    if (toastId) {
      toast.close(toastId);
    }

    if (isNewVersionAvailable) {
      versionToastId.current = toast({
        position: `bottom-${isAr ? 'left' : 'right'}`,
        status: 'info',
        duration: null,
        isClosable: true,
        render: ({ onClose }) => <VersionUpdateToast onClose={onClose} />,
      });
    }
  }, [isNewVersionAvailable, toast, isAr]);

  return (
    <div className="min-h-full flex">
      <Routes>
        <Route path="sign-up">
          <Route index element={<SignUp />} />
          <Route path="verify-otp">
            <Route index element={<VerifyOTP />} />
            <Route path="success" element={<VerifyOTPSuccess />} />
          </Route>
        </Route>
        <Route path="sign-in">
          <Route index element={<SignIn />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="create-password">
            <Route index element={<CreatePassword />} />
            <Route path="success" element={<CreatePasswordSuccess />} />
          </Route>
        </Route>
        <Route path="magic_links">
          <Route path="new" element={<New />} />
          <Route path="validate" element={<Validate />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<Overview />} />
            <Route path="inbounds">
              <Route index element={<Inbounds />} />
              <Route path=":id" element={<InboundDetails />} />
              <Route path="create" element={<InboundCreate />} />
              <Route path=":id/assign" element={<InboundAssign />} />
              <Route path=":id/edit" element={<InboundEdit />} />
            </Route>
            <Route path="outbounds">
              <Route index element={<Outbounds />} />
              <Route path=":id" element={<OutboundDetails />} />
              <Route path="create" element={<OutboundCreate />} />
              <Route path=":id/assign" element={<OutboundAssign />} />
              <Route path=":id/edit" element={<OutboundEdit />} />
            </Route>
            <Route path="transportations">
              <Route index element={<Transportations />} />
              <Route path="create" element={<TransportationCreate />} />
              <Route path=":id" element={<TransportationDetails />} />
              <Route path=":id/summary" element={<TransportationSummary />} />
            </Route>
            <Route path="addresses">
              <Route index element={<Addresses />} />
              <Route path="create" element={<CreateAddress />} />
              <Route path=":id" element={<AddressDetails />} />
            </Route>
            <Route path="suppliers" element={<Suppliers />} />
            <Route path="warehouses" element={<WarehousesPage />}>
              <Route index element={<Warehouses />} />
              <Route path="quotations" element={<Quotations />} />
            </Route>
            <Route path="warehouses/quote/:id" element={<ReviewQuote />} />
            <Route path="warehouses/success" element={<WarehouseSuccess />} />
            <Route path="skus">
              <Route index element={<SKUs />} />
              <Route path=":id" element={<SkuDetails />} />
              <Route path="create" element={<SKUCreate />} />
              <Route path=":id/edit" element={<SKUEdit />} />
              <Route
                path=":id/associated-movements"
                element={<AssociatedMovements />}
              />
            </Route>
            <Route path="reports" element={<Reports />} />
            <Route path="sku-groups">
              <Route index element={<SKUGroups />} />
              <Route path=":id" element={<SKUGroupsEdit />} />
              <Route path="create" element={<SKUGroupsCreate />} />
            </Route>
            <Route path="settings" element={<Settings />}>
              <Route index element={<Profile />} />
              <Route path="organization" element={<Organization />} />
              <Route path="user-management" element={<UserManagement />} />
              <Route
                path="push-notifications"
                element={<NotificationSettings />}
              />
              <Route path="billing" element={<Billing />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="lead-quote" element={<OnboardingLayout />}>
            <Route index element={<Lead />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default App;
