import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'components/Dashboard/Select';
import { packagingEnum } from 'constants/dashboard';
import AsyncSelect from 'components/Dashboard/AsyncSelect';
import { getOutboundSKUConfigurations, getSKUs } from 'api/Dashboard/skus';
import Table from 'components/Dashboard/Table';
import useTable from 'hooks/useTable';
import { forwardRef, useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import CounterTag from 'components/Dashboard/CounterTag';
import Tooltip from 'components/shared/Tooltip';
import { getFormSelectDefaultValue } from 'utils/select';

const QuantityInput = forwardRef(
  ({ fieldState, min, max, isSelected, name, ...restField }, ref) => {
    const { t } = useTranslation();

    return (
      <FormControl isInvalid={fieldState.invalid}>
        <NumberInput
          clampValueOnBlur={false}
          min={min}
          size="lg"
          borderRadius="2xl"
          max={max}
          {...restField}
          isDisabled={!isSelected}
        >
          <NumberInputField placeholder={t('quantity')} ref={ref} name={name} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

QuantityInput.displayName = 'QuantityInput'; // Add a display name for better debugging

const LongList = ({ items }) => {
  if (!items?.length) return '-';

  if (items.length <= 3) {
    return (
      <Stack spacing={1}>
        {items.map((date) => (
          <Text key={date}>{date}</Text>
        ))}
      </Stack>
    );
  }

  const remainingDates = items.slice(2);

  return (
    <Stack spacing={1}>
      <Text>{items[0]}</Text>
      <Text>{items[1]}</Text>
      <Tooltip
        label={
          <Stack spacing={1}>
            {remainingDates.map((date, index) => (
              <Text key={`${date}-${index}`}>{date}</Text>
            ))}
          </Stack>
        }
      >
        <Text
          sx={{
            color: 'blue.600',
            fontWeight: 500,
            cursor: 'pointer',
            width: 'fit-content',
          }}
        >
          +{items.length - 2}
        </Text>
      </Tooltip>
    </Stack>
  );
};

const columnHelper = createColumnHelper();

const OutboundCartForm = ({
  onSubmit,
  outboundId,
  backendErrors,
  isWithinEmptyTable,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  const { control: filtersControl, reset: resetFilters } = useForm();

  const {
    control: cartControl,
    handleSubmit: cartSubmit,
    reset: cartReset,
    getValues: getCartValues,
  } = useForm();

  const filterValues = useWatch({ control: filtersControl });
  const hasFilters = Object.keys(filterValues).length;
  const hasValidFilters =
    hasFilters &&
    Object.values(filterValues).every(
      (value) => value !== undefined && value !== null && value !== ''
    );

  const {
    data: skus = [],
    isLoading,
    isRefetching,
    refetch: refetchSKUs,
    rowSelection,
    onRowSelectionChange,
  } = useTable({
    fetch: async () => {
      return getOutboundSKUConfigurations(
        filterValues.sku_id,
        filterValues.packaging,
        outboundId
      );
    },
    fetchKey: [
      'outbound-sku-configurations',
      filterValues.sku_id,
      filterValues.packaging,
    ],
    disableURLParams: true,
    enabled: false,
  });

  useEffect(() => {
    if (hasValidFilters) {
      refetchSKUs();
      cartReset();
    }
  }, [hasValidFilters, refetchSKUs, filterValues, cartReset]);

  const packagingOptions = Object.keys(packagingEnum).map((key) => ({
    label: t(key),
    value: key,
  }));

  const hasSelectedSKUs = Object.keys(rowSelection)?.length;

  const columns = [
    columnHelper.accessor('packaging_type', {
      cell: ({ getValue }) => (
        <Center>
          <CounterTag
            colorScheme="yellow"
            count={getValue()}
            width="fit-content"
          />
        </Center>
      ),
      header: t('type'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('boxes_per_pallet', {
      cell: ({ getValue }) => (
        <Center>
          <CounterTag
            colorScheme="green"
            count={getValue()}
            width="fit-content"
          />
        </Center>
      ),
      header: t('casesPallets'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('units_per_box', {
      cell: ({ getValue }) => (
        <Center>
          <CounterTag
            colorScheme="purple"
            count={getValue()}
            width="fit-content"
          />
        </Center>
      ),
      header: t('unitsCase'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('expiry_dates', {
      cell: ({ getValue }) => {
        const expiryDates = getValue();

        return <LongList items={expiryDates} />;
      },
      header: t('expiryDates'),
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('on_hand_inventory', {
      header: t('onHandInventory'),
      cell: ({ getValue }) => <Center>{getValue()}</Center>,
      meta: {
        centerHeader: true,
      },
    }),
    columnHelper.accessor('', {
      header: t('selectedQuantity'),
      cell: ({ getValue, row }) => {
        const { boxes_per_pallet, units_per_box } = row.original;
        const id = `${boxes_per_pallet}-${units_per_box}-${filterValues.packaging}`;
        const min = 1;
        const max = row.original.on_hand_inventory;
        const isSelected = row.getIsSelected();
        const name = id;

        return (
          <Center>
            <Box maxWidth="120px">
              <Controller
                name={name}
                control={cartControl}
                rules={{
                  required: isSelected ? t('thisFieldIsRequired') : false,
                  min: {
                    value: isSelected ? min : undefined,
                    message: `${t('minIs')} ${min}`,
                  },
                  max: {
                    value: isSelected ? max : undefined,
                    message: `${t('maxIs')} ${max}`,
                  },
                }}
                render={({ field: { ref, ...restField }, fieldState }) => (
                  <QuantityInput
                    fieldState={fieldState}
                    min={min}
                    max={max}
                    isSelected={isSelected}
                    ref={ref}
                    {...restField}
                  />
                )}
              />
            </Box>
          </Center>
        );
      },
      meta: {
        centerHeader: true,
      },
    }),
  ];

  const getOutboundSKUs = async (urlSearchParams) => {
    return await getSKUs(
      `${urlSearchParams}&q[s]=created_at+desc&in_inventory=true&outbound_id=${outboundId}`
    );
  };

  const onCartSubmit = (configurations) => {
    const values = getCartValues();

    // filter the unselected configurations
    const selectedConfigurations = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== undefined)
    );

    onSubmit(
      {
        sku_id: filterValues.sku_id,
        packaging: filterValues.packaging,
        configurations: selectedConfigurations,
      },
      () => {
        resetFilters({
          sku_id: '',
          packaging: '',
        });
      }
    );
  };

  return (
    <Stack
      spacing={6}
      paddingBottom={6}
      marginTop={isWithinEmptyTable ? -12 : 0}
    >
      <Box
        width="full"
        maxWidth={{ base: 'full', md: isWithinEmptyTable ? '665px' : 'full' }}
        margin={{
          base: undefined,
          md: isWithinEmptyTable ? '0 auto' : undefined,
        }}
        noValidate
      >
        <Text
          color="primary.700"
          fontWeight={500}
          marginBottom={5}
          fontSize="lg"
          textAlign={isWithinEmptyTable ? 'center' : 'start'}
        >
          {t('pleaseSelectSKUToAddToCart')}
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={5}
          textAlign="start"
          color="gray.700"
        >
          <Controller
            control={filtersControl}
            name="sku_id"
            rules={{ required: 'thisFieldIsRequired' }}
            render={({ field: { onChange, value } }) => (
              <AsyncSelect
                performSearchRequest={getOutboundSKUs}
                entityKey="skus"
                searchKey="name_or_merchant_sku_or_system_sku_cont"
                placeholder={t('selectSKU')}
                onChange={(option) => onChange(option?.value)}
                value={value}
                required
              />
            )}
          />

          <Controller
            control={filtersControl}
            name="packaging"
            rules={{ required: 'thisFieldIsRequired' }}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder={t('selectPackaging')}
                options={packagingOptions}
                onChange={({ value }) => onChange(value)}
                value={getFormSelectDefaultValue(value, packagingOptions) ?? ''}
                required
              />
            )}
          />
        </SimpleGrid>
      </Box>

      {hasValidFilters && (
        <form onSubmit={cartSubmit(onCartSubmit)} noValidate>
          <Table
            data={skus}
            columns={columns}
            isLoading={isLoading || isRefetching}
            rowSelection={rowSelection}
            onRowSelectionChange={(updater) => {
              onRowSelectionChange(updater);
            }}
            enableSorting={false}
            enableRowSelection
            hidePagination
          />

          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: { base: 'flex-start', md: 'center' },
              paddingTop: { base: 3, md: 5 },
              flexDirection: { base: 'column-reverse', md: 'row' },
              gap: 5,
            }}
          >
            <Text fontSize="sm" color="gray.700" fontWeight={500}>
              {t('addToCartNote')}
            </Text>
            <Button
              size="lg"
              colorScheme="primary"
              type="submit"
              isDisabled={!hasSelectedSKUs}
              width={{ base: 'full', md: 'auto' }}
              isLoading={isSubmitting}
            >
              {t('addToCart')}
            </Button>
          </Flex>
        </form>
      )}
    </Stack>
  );
};

export default OutboundCartForm;
