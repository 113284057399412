import { memo, useEffect, useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';

const DebouncedNumberInput = memo(
  ({
    name,
    onChange,
    defaultValue,
    isDisabled,
    placeholder,
    min,
    max,
    size = 'lg',
    errorMessage,
    required,
  }) => {
    const [value, setValue] = useState(defaultValue);
    const [debouncedValue, setDebouncedValue] = useState(defaultValue);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }, [value]);

    useDidUpdateEffect(() => {
      onChange(debouncedValue);
    }, [debouncedValue]);

    return (
      <FormControl isInvalid={errorMessage} isRequired={required}>
        <NumberInput
          min={min}
          max={max}
          isRequired={required}
          size={size}
          borderRadius="2xl"
          onChange={(_, value) => {
            if (isNaN(value)) {
              setValue(0);
              return;
            }

            setValue(value);
          }}
          value={value}
          defaultValue={defaultValue}
          clampValueOnBlur={false}
          isDisabled={isDisabled}
        >
          <NumberInputField placeholder={placeholder} name={name} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </FormControl>
    );
  }
);

export default DebouncedNumberInput;
