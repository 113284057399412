import api from 'utils/axios';

export const getOutbounds = async (searchParams) => {
  try {
    const response = await api(`/merchants/outbounds?${searchParams}`);
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getOutboundById = async (id) => {
  try {
    const response = await api(`/merchants/outbounds/${id}`);
    const { data } = response;

    return data.data;
  } catch (error) {
    throw error;
  }
};

export const getOutboundSKUs = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/outbounds/${id}/skus?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const addSkuToOutbound = async (id, outbound_sku) => {
  try {
    const response = await api.post(`/merchants/outbounds/${id}/add_sku`, {
      outbound_sku,
    });
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const cancelOutbound = async (id) => {
  try {
    const response = await api.patch(`/merchants/outbounds/${id}/cancel`);
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const printPickingList = async (id) => {
  try {
    const response = await api(`/merchants/outbounds/${id}/print_picker_list`, {
      responseType: 'blob',
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const editOutboundSKU = async ({ id, skuID, outbound_sku }) => {
  try {
    const response = await api.patch(
      `/merchants/outbounds/${id}/update_sku/${skuID}`,
      { outbound_sku }
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const removeOutboundSKU = async ({ id, skuID }) => {
  try {
    const response = await api.delete(
      `/merchants/outbounds/${id}/remove_sku/${skuID}`
    );
    const { data } = response;

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateOutbound = async (id, outbound) => {
  try {
    const response = await api.patch(`/merchants/outbounds/${id}`, {
      outbound,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const requestNewOutbound = async (id) => {
  try {
    const response = await api('/merchants/outbounds/new');

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createOutbound = async (outbound) => {
  try {
    const response = await api.post('/merchants/outbounds', { outbound });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitOutboundForReview = async (id) => {
  try {
    const response = await api.post(`/merchants/outbounds/${id}/submit`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChargeableActivitiesSummary = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/outbounds/${id}/chargeable_summary?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getChargeableActivitiesByOutbound = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/outbounds/${id}/chargeable_by_outbound?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getChargeableActivitiesBySKU = async (id, searchParams) => {
  try {
    const response = await api(
      `/merchants/outbounds/${id}/chargeable_by_sku?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const getTransportationChargeableActivities = async (
  id,
  searchParams
) => {
  try {
    const response = await api(
      `/merchants/outbounds/${id}/chargeable_by_transportation?${searchParams}`
    );
    const { data } = response;

    return { data: data.data, meta: data.meta };
  } catch (error) {
    throw error;
  }
};

export const createOutboundRate = async (id, review) => {
  try {
    const response = await api.post(`/merchants/outbounds/${id}/reviews`, {
      review,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addToCart = async (id, payload) => {
  try {
    const response = await api.post(
      `/merchants/outbounds/${id}/add_to_cart`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCartQuantity = async (id, payload) => {
  try {
    const response = await api.patch(
      `/merchants/outbounds/${id}/edit_quantity`,
      payload
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
