import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { getPageName, logEvent } from 'utils/amplitude';
import useLanguage from './useLanguage';

export const useAmplitudePageTracking = () => {
  const location = useLocation();
  const isFirstRender = useRef(true);
  const { language } = useLanguage();

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const pageName = getPageName(location.pathname, location.search);
    const pageSearch = Object.fromEntries(new URLSearchParams(location.search));

    logEvent('Page Viewed', {
      page_name: pageName,
      page_language: language,
      page_search: pageSearch,
    });
  }, [location.pathname, location.search, language]);
};
